export default {
  data() {
    return {
      sipData: null,
      userId: null,
      userName: null,
      userEmail: null,
      userLevel: null,
      userAccess: null,
      userToken: null,
      userPhoto: "../assets/img/user.png",
    };
  },
  created() {
    const sipData = localStorage.getItem("sipData");
    if (sipData) {
      this.sipData = JSON.parse(sipData);
      this.userId = this.sipData.id;
      this.userName = this.sipData.nama;
      this.userEmail = this.sipData.email;
      this.userLevel = this.sipData.level;
      this.userAccess = this.sipData.hak_akses;
      this.userToken = this.sipData.token;
      // this.userPhoto = this.sipData.foto;
    }
  },
};
