<script>
const sidebarMenu = [
  { path: "/dashboard", icon: "fa fa-laptop", title: "DASHBOARD" },
  { path: "/data-pelanggan", icon: "fa fa-users", title: "DATA PELANGGAN" },
  { path: "/data-transaksi", icon: "fa fa-file-alt", title: "DATA TRANSAKSI" },
  { path: "/export-data", icon: "fa fa-file-excel", title: "EXPORT DATA" },
  {
    path: "/pengaduan",
    icon: "fa fa-trash",
    title: "LAPORAN SAMPAH",
  },
  {
    path: "/master",
    icon: "fa fa-book",
    title: "MASTER DATA",
    children: [
      { path: "/master/jenis-kegiatan", title: "JENIS KEGIATAN" },
      { path: "/master/rekening-koran", title: "REKENING KORAN" },
      { path: "/master/data-potensial", title: "DATA POTENSIAL" },
      { path: "/master/instansi", title: "INSTANSI" },
    ],
  },
  {
    path: "/pengguna",
    icon: "fa fa-user",
    title: "USER MANAGEMENT",
  },
];

export default sidebarMenu;
</script>
