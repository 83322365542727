<template>
  <div>
    <button
      class="pull-right btn btn-icon btn-circle btn-light"
      :class="{
        'fa-spin':
          this.isLoadingA ||
          this.isLoadingB ||
          this.isLoadingC ||
          this.isLoadingD ||
          this.isLoadingE ||
          this.isLoadingF ||
          this.isLoadingG ||
          this.isLoadingH ||
          this.isLoadingI ||
          this.isLoadingJ,
      }"
      @click="fetchData()"
    >
      <i class="fa fa-sync"></i>
    </button>
    <h1 class="page-header">Dashboard <small></small></h1>
    <div class="row">
      <div class="col-6 col-md-2">
        <select
          v-model="filter.year"
          v-on:change="
            fetchData();
            filter.month = '';
          "
          class="form-control mb-2"
        >
          <option value="">Pilih Tahun</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
        </select>
      </div>
      <div class="col-6 col-md-2">
        <select
          v-model="filter.month"
          v-if="filter.year"
          v-on:change="fetchData()"
          class="form-control mb-2"
        >
          <option value="">Pilih Bulan</option>
          <option value="01">Januari</option>
          <option value="02">Februari</option>
          <option value="03">Maret</option>
          <option value="04">April</option>
          <option value="05">Mei</option>
          <option value="06">Juni</option>
          <option value="07">Juli</option>
          <option value="08">Agustus</option>
          <option value="09">September</option>
          <option value="10">Oktober</option>
          <option value="11">November</option>
          <option value="12">Desember</option>
        </select>
      </div>
    </div>
    <div class="row" v-if="userLevel != 'satgas' && userLevel != 'penagih'">
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-teal">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-users fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH PELANGGAN</div>
            <div class="stats-number">
              {{ this.numberFormat(this.total_customer) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-blue">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-ticket-alt fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TRANSAKSI</div>
            <div class="stats-number">
              {{ this.numberFormat(this.total_transaction) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4">
        <div class="widget widget-stats bg-info">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH PENERIMAAN HARI INI</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_payment_day) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-md-4"
        v-if="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN TUNAI</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_payment) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-md-4"
        v-if="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN NON TUNAI</div>
            <div class="stats-number">
              {{
                this.currencyFormat(
                  this.total_online_payment - this.total_payment
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-xl-4 col-md-4"
        v-if="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div class="widget widget-stats bg-green">
          <div class="stats-icon stats-icon-lg">
            <i class="fa fa-wallet fa-fw"></i>
          </div>
          <div class="stats-content">
            <div class="stats-title">JUMLAH TOTAL PENERIMAAN</div>
            <div class="stats-number">
              {{ this.currencyFormat(this.total_online_payment) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-12 mb-3"
        v-show="userLevel != 'satgas' && userLevel != 'penagih'"
      >
        <div v-if="this.isLoadingF">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barDaily"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingI">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barComparison"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingD">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barUsers"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="userLevel != 'satgas' && userLevel != 'penagih'"
      >
        <div v-if="this.isLoadingE">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barMonths"></div>
      </div>

      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingB">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="drilldownDistrict"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingB">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="pieDistrict"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="userLevel != 'satgas' && userLevel != 'penagih'"
      >
        <div v-if="this.isLoadingC">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barCategory" style="height: 100vh"></div>
      </div>
    </div>

    <div class="row" v-show="userLevel != 'kelurahan'">
      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingG">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barPerformance" style="min-height: 80vh"></div>
      </div>
      <div
        class="col-md-12 mb-3"
        v-show="
          userLevel != 'kelurahan' &&
          userLevel != 'satgas' &&
          userLevel != 'penagih'
        "
      >
        <div v-if="this.isLoadingH">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barPerformanceSatgas" style="min-height: 80vh"></div>
      </div>
      <div class="col-md-12 mb-3" v-show="userLevel != 'kelurahan'">
        <div v-if="this.isLoadingJ">
          <div class="bg-light text-center text-info p-40">
            <h3>Loading Grafik</h3>
            <i class="fa fa-circle-notch fa-spin f-s-20"></i>
          </div>
        </div>
        <div ref="barComparisonCustomers"></div>
      </div>
    </div>
    <div class="row" v-if="userLevel == 'satgas' || userLevel == 'penagih'">
      <div class="col-md-12">
        <router-link
          to="/data-pelanggan"
          class="btn btn-lg btn-primary btn-block"
        >
          Data Pelanggan
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import sipData from "../plugins/sipData";
import Highcharts from "highcharts";
import drilldown from "highcharts/modules/drilldown";
import exporting from "highcharts/modules/exporting";
import exportingExcel from "highcharts/modules/export-data";
import accessibility from "highcharts/modules/accessibility";
import seriesLabel from "highcharts/modules/series-label";
import highContrastLight from "highcharts/themes/high-contrast-light";
import highContrastData from "highcharts/modules/data";
import moment from "moment";

// Inisialisasi modul Highcharts
drilldown(Highcharts);
exporting(Highcharts);
exportingExcel(Highcharts);
accessibility(Highcharts);
seriesLabel(Highcharts);
highContrastLight(Highcharts);
highContrastData(Highcharts);

export default {
  mixins: [sipData],
  name: "dashboard",
  data() {
    return {
      filter: {
        year: "",
        month: "",
      },
      isLoadingA: false,
      isLoadingB: false,
      isLoadingC: false,
      isLoadingD: false,
      isLoadingE: false,
      isLoadingF: false,
      isLoadingG: false,
      isLoadingH: false,
      isLoadingI: false,
      isLoadingJ: false,
      total_customer: 0,
      total_transaction: 0,
      total_payment: 0,
      total_online_payment: 0,
      total_payment_day: 0,
      transaction_by_district: [],
      transaction_by_village: [],
      transaction_by_category: [],
      transaction_by_users: [],
      transaction_by_months: [],
      transaction_by_date: [],
      biller_performance: [],
      satgas_performance: [],
      customer_comparison_district: [],
      customer_comparison_village: [],
      customer_district: [],
      customer_village: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.fetchA();
      this.fetchB();
      this.fetchC();
      this.fetchD();
      this.fetchE();
      this.fetchF();
      this.fetchG();
      this.fetchH();
      this.fetchI();
      this.fetchJ();
    },
    fetchA() {
      this.isLoadingA = true;
      axios
        .get("/v1/dashboard/", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.total_customer = response.data.total_customer;
          this.total_transaction = response.data.total_transaction;
          this.total_payment = response.data.total_payment;
          this.total_online_payment = response.data.total_online_payment;
          this.total_payment_day = response.data.total_payment_day;
          this.isLoadingA = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchB() {
      this.isLoadingB = true;
      axios
        .get("/v1/dashboard/transaction_by_district", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.transaction_by_district = response.data.transaction_by_district;
          this.transaction_by_village = response.data.transaction_by_village;
          this.isLoadingB = false;
          this.renderDrilDownDisctrict();
          this.renderPieDistrict();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchC() {
      this.isLoadingC = true;
      axios
        .get("/v1/dashboard/transaction_by_category", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.transaction_by_category = response.data.transaction_by_category;
          this.isLoadingC = false;
          this.renderBarCategory();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchD() {
      this.isLoadingD = true;
      axios
        .get("/v1/dashboard/transaction_by_users", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.transaction_by_users = response.data.transaction_by_users;
          this.isLoadingD = false;
          this.renderBarUsers();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchE() {
      this.isLoadingE = true;
      axios
        .get("/v1/dashboard/transaction_by_months", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.transaction_by_months = response.data.transaction_by_months;
          this.isLoadingE = false;
          this.renderBarMonths();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchF() {
      this.isLoadingF = true;
      axios
        .get("/v1/dashboard/transaction_by_date", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.transaction_by_date = response.data.transaction_by_date;
          this.isLoadingF = false;
          this.renderBarDaily();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchG() {
      this.isLoadingG = true;
      axios
        .get("/v1/dashboard/biller_performance", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.biller_performance = response.data.biller_performance;
          this.isLoadingG = false;
          this.renderPerformance();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchH() {
      this.isLoadingH = true;
      axios
        .get("/v1/dashboard/satgas_performance", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.satgas_performance = response.data.satgas_performance;
          this.isLoadingH = false;
          this.renderPerformanceSatgas();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchI() {
      this.isLoadingI = true;
      axios
        .get("/v1/dashboard/customer_comparison", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.satgas_performance = response.data.satgas_performance;
          this.customer_comparison_district =
            response.data.customer_comparison_district;
          this.customer_comparison_village =
            response.data.customer_comparison_village;
          this.isLoadingI = false;
          this.renderCustomerComparison();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchJ() {
      this.isLoadingJ = true;
      axios
        .get("/v1/dashboard/customer", {
          headers: {
            token: this.userToken,
          },
          params: {
            year: this.filter.year,
            month: this.filter.month,
          },
        })
        .then((response) => {
          this.customer_district = response.data.customer_district;
          this.customer_village = response.data.customer_village;
          this.isLoadingJ = false;
          this.renderCustomers();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    renderCustomers() {
      var kecamatanData = this.customer_district;
      var kelurahanData = this.customer_village;

      var processedData = [];

      kecamatanData.forEach(function (kecamatan) {
        var idPrefix = kecamatan.id_kec + "-";

        // Process sudah bayar data
        processedData.push({
          id: idPrefix + "D",
          name: "Realisasi Pelanggan",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.pelanggan_baru)];
            }),
        });

        // Process belum bayar data
        processedData.push({
          id: idPrefix + "U",
          name: "Potensi",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.pelanggan_lama)];
            }),
        });
      });

      Highcharts.chart(this.$refs.barComparisonCustomers, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Perbandingan Migrasi Data Pelanggan Berdasarkan Data Potensial",
        },
        subtitle: {
          text: "Klik Nama Kecamatan Untuk Melihat Detail/Kelurahan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Potensi",
            data: this.customer_district.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.pelanggan_lama),
              drilldown: data.id_kec + "-U",
            })),
            color: "#265FB5",
          },
          {
            name: "Realisasi Pelanggan",
            data: this.customer_district.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.pelanggan_baru),
              drilldown: data.id_kec + "-D",
            })),
            color: "#698F01",
          },
        ],
        drilldown: { allowPointDrilldown: true, series: processedData },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderCustomerComparison() {
      var kecamatanData = this.customer_comparison_district;
      var kelurahanData = this.customer_comparison_village;

      var processedData = [];

      kecamatanData.forEach(function (kecamatan) {
        var idPrefix = kecamatan.id_kec + "-";

        // Process sudah bayar data
        processedData.push({
          id: idPrefix + "D",
          name: "Sudah Bayar",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.sudah_bayar)];
            }),
        });

        // Process belum bayar data
        processedData.push({
          id: idPrefix + "U",
          name: "Belum Bayar",
          data: kelurahanData
            .filter(function (kelurahan) {
              return kelurahan.id_kec === kecamatan.id_kec;
            })
            .map(function (kelurahan) {
              return [kelurahan.nama_kel, parseInt(kelurahan.belum_bayar)];
            }),
        });
      });

      Highcharts.chart(this.$refs.barComparison, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Status Pembayaran Bulan Ini",
        },
        subtitle: {
          text: "Klik Nama Kecamatan Untuk Melihat Detail/Kelurahan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah",
          },
        },
        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [
          {
            name: "Belum Bayar",
            data: this.customer_comparison_district.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.belum_bayar),
              drilldown: data.id_kec + "-U",
            })),
            color: "#2D353C",
          },
          {
            name: "Sudah Bayar",
            data: this.customer_comparison_district.map((data) => ({
              name: data.nama_kec,
              y: parseInt(data.sudah_bayar),
              drilldown: data.id_kec + "-D",
            })),
            color: "#698F01",
          },
        ],
        drilldown: { allowPointDrilldown: true, series: processedData },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    drillDownCustomerComparison(kecamatanData) {
      const kelurahanData = this.customer_comparison_village;
      const kecamatanId = kecamatanData.id_kec;

      const belumBayarData = kelurahanData
        .filter(
          (data) =>
            data.id_kec === kecamatanId &&
            data.status_pembayaran === "belum_bayar"
        )
        .map(
          (kelurahan) => (kelurahan.nama_kel, parseInt(kelurahan.belum_bayar))
        );

      const sudahBayarData = kelurahanData
        .filter(
          (data) =>
            data.id_kec === kecamatanId &&
            data.status_pembayaran === "sudah_bayar"
        )
        .map(
          (kelurahan) => (kelurahan.nama_kel, parseInt(kelurahan.sudah_bayar))
        );

      return [belumBayarData, sudahBayarData];
    },
    renderDrilDownDisctrict() {
      Highcharts.chart(this.$refs.drilldownDistrict, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Berdasarkan Kecamatan",
        },
        subtitle: {
          text: "Klik Nama Kecamatan Untuk Melihat Detail/Kelurahan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_district.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
              drilldown: data.id_kec,
            })),
            colorByPoint: true,
          },
        ],
        drilldown: {
          series: this.transaction_by_district.map((kecamatanData) => ({
            id: kecamatanData.id_kec,
            name: kecamatanData.nama,
            data: this.drillDownDisctrict(kecamatanData),
          })),
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    drillDownDisctrict(kecamatanData) {
      const kelurahanData = this.transaction_by_village;
      const kecamatanId = kecamatanData.id_kec;

      return kelurahanData
        .filter((data) => data.id_kec === kecamatanId)
        .map((kelurahan) => ({
          name: kelurahan.nama,
          y: parseInt(kelurahan.jumlah),
        }));
    },
    renderPieDistrict() {
      Highcharts.chart(this.$refs.pieDistrict, {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Persentase Transaksi Berdasarkan Kecamatan",
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              distance: 5,
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            colorByPoint: true,
            data: this.transaction_by_district.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
              drilldown: data.id_kec,
            })),
          },
        ],
        drilldown: {
          series: this.transaction_by_district.map((kecamatanData) => ({
            id: kecamatanData.id_kec,
            name: kecamatanData.nama,
            data: this.drillDownDisctrict(kecamatanData),
          })),
        },
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarCategory() {
      Highcharts.chart(this.$refs.barCategory, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Berdasarkan Jenis Kegiatan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_category.map((data) => ({
              name: data.category,
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarUsers() {
      const jsonData = this.transaction_by_users;
      const userNames = jsonData.map((item) => item.nama_pengguna);
      const totalAmounts = jsonData.map((item) => parseInt(item.jumlah));
      const skrd = jsonData.map((item) => parseInt(item.skrd));
      const ssrd = jsonData.map((item) => parseInt(item.ssrd));

      Highcharts.chart(this.$refs.barUsers, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi dan Penerimaan Hari Ini",
        },
        xAxis: {
          categories: userNames,
          crosshair: true,
          accessibility: {
            description: "Users",
          },
          labels: {
            formatter: function () {
              // Format nama sesuai kebutuhan Anda di sini
              // Misalnya, mengonversi setiap kata menjadi huruf besar
              return this.value
                .split(" ")
                .map(function (word) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "Jumlah Penerimaan",
            },
            labels: {
              formatter: function () {
                return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
              },
            },
          },
          {
            title: {
              text: "Jumlah Transaksi",
            },
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
          formatter: function () {
            return (
              "<b style='text-transform: capitalize'> " +
              this.x +
              "</b>" +
              "</b><br/>Jumlah Penerimaan : <b>Rp. " +
              Highcharts.numberFormat(this.points[2].y, 0, ",", ".") +
              "</b>" +
              "<br/>SKRD Dikeluarkan : <b>" +
              this.points[0].y +
              "</b><br/>SSRD Dikeluarkan : <b>" +
              this.points[1].y
            );
          },
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              rotation: -90,
              color: "#FFFFFF",
              align: "right",
              y: 10,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        series: [
          {
            name: "SKRD",
            data: skrd,
            yAxis: 1,
            color: "#49B6D6",
          },
          {
            name: "SSRD",
            data: ssrd,
            yAxis: 1,
            color: "#265FB5",
          },
          {
            name: "Nilai Transaksi",
            data: totalAmounts,
            color: "#698F01",
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarMonths() {
      Highcharts.chart(this.$refs.barMonths, {
        chart: {
          type: "column",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Jumlah Transaksi Per Bulan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Kecamatan",
            data: this.transaction_by_months.map((data) => ({
              name: data.nama_bulan,
              y: parseInt(data.jumlah_transaksi),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderBarDaily() {
      Highcharts.chart(this.$refs.barDaily, {
        chart: {
          type: "spline",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Tren Transaksi Harian",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          title: {
            text: "Jumlah Penerimaan",
          },
          labels: {
            formatter: function () {
              return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
            },
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return "Rp. " + Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br>Rp. " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Jumlah Transaksi",
            data: this.transaction_by_date.map((data) => ({
              name: this.dateShortFormat(data.transaction_date),
              y: parseInt(data.jumlah_transaksi),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    numberFormat(value) {
      if (!value) return "0";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    currencyFormat(value) {
      if (!value) return "0";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateShortFormat(dateTime) {
      return moment(dateTime).format("DD-MM-YY");
    },
    capitalizeEachWord(str) {
      return str.replace(/\b\w/g, function (char) {
        return char.toLowerCase();
      });
    },
    renderPerformance() {
      const jsonData = this.biller_performance;
      const userNames = jsonData.map((item) => item.full_name);
      const costumers = jsonData.map((item) => parseInt(item.costumers));
      const transactions = jsonData.map((item) => parseInt(item.transactions));

      Highcharts.chart(this.$refs.barPerformance, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Performa Verifikator Kecamatan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          categories: userNames,
          crosshair: true,
          accessibility: {
            description: "Users",
          },
          labels: {
            formatter: function () {
              return this.value
                .split(" ")
                .map(function (word) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
            },
          },
        },
        yAxis: [
          {
            title: {
              text: "Jumlah Penerimaan",
            },
            labels: {
              formatter: function () {
                return this.value !== 0 ? this.value / 1000000 + " Juta" : "0";
              },
            },
          },
          {
            title: {
              text: "Jumlah",
            },
            opposite: true,
          },
        ],

        legend: {
          enabled: true,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return (
              "<b style='text-transform: capitalize'> " +
              this.x +
              "</b>" +
              "<br/>Pelanggan Terdata : <b>" +
              this.points[0].y +
              "</b><br/>Tagihan : <b>" +
              this.points[1].y
            );
          },
        },

        series: [
          {
            name: "Pelanggan Terdata",
            data: costumers,
            yAxis: 1,
            color: "#49B6D6",
          },
          {
            name: "Tagihan",
            data: transactions,
            yAxis: 1,
            color: "#265FB5",
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
    renderPerformanceSatgas() {
      Highcharts.chart(this.$refs.barPerformanceSatgas, {
        chart: {
          type: "bar",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Performa Satgas Kelurahan",
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        xAxis: {
          type: "category",
        },
        yAxis: {
          // tickInterval: 5,
          title: {
            text: "Jumlah",
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return Highcharts.numberFormat(this.y, 0, ",", ".");
              },
            },
          },
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b> : " +
              Highcharts.numberFormat(this.y, 0, ",", ".")
            );
          },
        },
        series: [
          {
            name: "Jumlah",
            data: this.satgas_performance.map((data) => ({
              name: data.nama,
              y: parseInt(data.jumlah),
            })),
            colorByPoint: true,
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ["viewFullscreen", "downloadJPEG", "downloadXLS"],
            },
            menuButtonTextKey: {
              viewFullscreen: "Tampilkan Layar Penuh",
            },
          },
        },
      });
    },
  },
};
</script>