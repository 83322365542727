import Vue from "vue";
import VueX from "vuex";
// import routes from './config/PageRoutes'

import router from "./router";
import "./plugins/axios";

// plugins
// import VueRouter from 'vue-router'
import VueBootstrap from "bootstrap-vue";
import VueInsProgressBar from "vue-ins-progress-bar";
// import * as VueGoogleMaps from "vue2-google-maps";
const VueNVD3 = () => import("vue-nvd3");
const VueEventCalendar = () => import("vue-event-calendar");
const VueSparkline = () => import("vue-sparklines");
const Vueditor = () => import("@agametov/vueditor");
const VueHljs = () => import("vue-hljs");
const VueSweetalert2 = () => import("vue-sweetalert2");
const VueNotification = () => import("vue-notification");
const VuePanel = () => import("./plugins/panel/");
const VueDateTimePicker = () => import("vue-bootstrap-datetimepicker");
const VueSelect = () => import("vue-select");
const VueDatepicker = () =>
  import("vuejs-datepicker/dist/vuejs-datepicker.esm.js");
const VueMaskedInput = () => import("vue-maskedinput");
const VueInputTag = () => import("vue-input-tag");
const VueSlider = () => import("vue-slider-component");
import VueGoodTable from "vue-good-table";
const VueFullCalendar = () => import("vue-full-calendar");
const VueCountdown = () => import("@chenfengyuan/vue-countdown");
const VueColorpicker = () => import("vue-pop-colorpicker");
const VueCustomScrollbar = () => import("vue-custom-scrollbar");
const VueApexCharts = () => import("vue-apexcharts");
const DateRangePicker = () => import("vue2-daterange-picker");
const CKEditor = () => import("ckeditor4-vue");

// plugins css
import "bootstrap-vue/dist/bootstrap-vue.css";
import "nvd3/build/nv.d3.min.css";
import "vue-event-calendar/dist/style.css";
import "vue-hljs/dist/vue-hljs.min.css";
import "@agametov/vueditor/dist/style/vueditor.min.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import "simple-line-icons/css/simple-line-icons.css";
import "flag-icon-css/css/flag-icon.min.css";
import "ionicons/dist/css/ionicons.min.css";
// import "vue-good-table/dist/vue-good-table.css";
import "fullcalendar/dist/fullcalendar.css";
import "vue-select/dist/vue-select.css";
import "vue-slider-component/theme/antd.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "bootstrap-social/bootstrap-social.css";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "quill/dist/quill.snow.css";

// color admin css
import "./scss/vue.scss";

import App from "./App.vue";

Vue.config.productionTip = false;

Vue.use(VueX);
Vue.use(VueBootstrap);
Vue.use(CKEditor);
Vue.use(VueNVD3);
Vue.use(VueEventCalendar, { locale: "en" });
Vue.use(VueSparkline);
Vue.use(Vueditor);
Vue.use(VueHljs);
Vue.use(VueSweetalert2);
Vue.use(VueNotification);
Vue.use(VuePanel);
Vue.use(VueDateTimePicker);
Vue.use(VueGoodTable);
Vue.use(VueFullCalendar);
Vue.use(VueMaskedInput);
Vue.use(VueColorpicker);
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: "",
//     libraries: "places",
//   },
// });
Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "3px",
});
Vue.component("v-select", VueSelect);
Vue.component("datepicker", VueDatepicker);
Vue.component("masked-input", VueMaskedInput);
Vue.component("input-tag", VueInputTag);
Vue.component("vue-slider", VueSlider);
Vue.component("vue-custom-scrollbar", VueCustomScrollbar);
Vue.component("apexchart", VueApexCharts);
Vue.component("date-range-picker", DateRangePicker);
Vue.component(VueCountdown.name, VueCountdown);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
