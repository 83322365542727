import Vue from "vue";
import VueRouter from "vue-router";

// import axios from "axios";
// import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
// import Home from "../pages/public/Home.vue";
// import Status from "../pages/public/Status.vue";
// import Payment from "../pages/public/Payment.vue";

const routes = [
  {
    path: "/",
    component: () => import("../pages/public/Home.vue"),
  },
  {
    path: "*",
    component: () => import("../pages/public/Home.vue"),
  },
  {
    path: "/privacy-policy",
    component: () => import("../pages/public/PrivacyPolicy.vue"),
  },
  {
    path: "/report",
    component: () => import("../pages/public/Report.vue"),
  },
  {
    path: "/payment",
    component: () => import("../pages/public/Payment.vue"),
  },
  {
    path: "/status/:id",
    component: () => import("../pages/public/Status.vue"),
  },
  {
    path: "/home/",
    component: () => import("../pages/public/Home.vue"),
  },
  {
    path: "/registrasi",
    component: () => import("../pages/public/Registrasi.vue"),
  },
  {
    path: "/forbidden",
    component: () => import("../pages/Forbidden.vue"),
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/login",
    component: () => import("../pages/Login.vue"),
  },
  {
    path: "/pengguna",
    component: () => import("../pages/users/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/update/:id",
    component: () => import("../pages/users/Edit.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/password/:id",
    component: () => import("../pages/users/Password.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengguna/create",
    component: () => import("../pages/users/Form.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/data-pelanggan",
    component: () => import("../pages/customers/List.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan", "penagih", "satgas"],
    },
  },
  {
    path: "/export-data/",
    component: () => import("../pages/export-data/List.vue"),
    meta: {
      requiredLevel: ["admin", "kelurahan"],
    },
  },
  {
    path: "/data-pelanggan/import",
    component: () => import("../pages/customers/Import.vue"),
    meta: {
      requiredLevel: ["admin"],
    },
  },
  {
    path: "/data-pelanggan/create",
    component: () => import("../pages/customers/Form.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan", "penagih", "satgas"],
    },
  },
  {
    path: "/data-pelanggan/update/:id",
    component: () => import("../pages/customers/Update.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan", "penagih", "satgas"],
    },
  },
  {
    path: "/data-pelanggan/detail/:id",
    component: () => import("../pages/customers/Detail.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan", "penagih", "satgas"],
    },
  },

  {
    path: "/data-transaksi",
    component: () => import("../pages/transactions/List.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/create",
    component: () => import("../pages/transactions/Form.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/bulk",
    component: () => import("../pages/transactions/Bulk.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/detail/:id",
    component: () => import("../pages/transactions/Detail.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/print/:id",
    component: () => import("../pages/transactions/Print.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/print/:id",
    component: () => import("../pages/transactions/Print.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/skrd/:id",
    component: () => import("../pages/transactions/Skrd.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/data-transaksi/ssrd/:id",
    component: () => import("../pages/transactions/Ssrd.vue"),
    meta: {
      requiredLevel: ["admin", "loket", "kelurahan"],
    },
  },
  {
    path: "/master/jenis-kegiatan/",
    component: () => import("../pages/master/jenis-kegiatan/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/rekening-koran/",
    component: () => import("../pages/master/rekening-koran/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/rekening-koran/import",
    component: () => import("../pages/master/rekening-koran/Import.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/data-potensial/",
    component: () => import("../pages/master/potential/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/data-potensial/import",
    component: () => import("../pages/customers/Import.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/master/instansi",
    component: () => import("../pages/master/instansi/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengaduan/",
    component: () => import("../pages/reports/List.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/pengaduan/detail/:id",
    component: () => import("../pages/reports/Detail.vue"),
    meta: {
      requiredLevel: "admin",
    },
  },
  {
    path: "/profil",
    component: () => import("../pages/profil/Profil.vue"),
  },
  {
    path: "/profil/:page",
    component: () => import("../pages/profil/Profil.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (
    to.path !== "/" &&
    to.path !== "/login" &&
    !isLoggedIn &&
    to.path !== "/registrasi" &&
    to.path !== "/home" &&
    to.path !== "/payment" &&
    to.path !== "/report" &&
    to.path !== "/privacy-policy" &&
    !to.path.includes("/status/")
  ) {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
