<template>
  <ul class="nav" v-if="filteredSidebarMenu">
    <li class="nav-header">Menu</li>
    <template v-for="menu in filteredSidebarMenu">
      <sidebar-nav-list
        ref="sidebarNavList"
        v-bind:menu="menu"
        v-bind:scrollTop="scrollTop"
        v-bind:key="menu.path"
        v-bind:status="menu.status"
        v-on:collapse-other="handleCollapseOther(menu)"
        v-on:show-float-submenu="handleShowFloatSubmenu"
        v-on:hide-float-submenu="handleHideFloatSubmenu"
      ></sidebar-nav-list>
    </template>
  </ul>
</template>

<script>
import SidebarMenu from "./SidebarMenu.vue";
import SidebarNavList from "./SidebarNavList.vue";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";

export default {
  name: "SidebarNav",
  props: ["scrollTop"],
  components: {
    SidebarNavList,
  },
  data() {
    return {
      userLevel: "user",
      sidebarMenu: SidebarMenu,
      pageOptions: PageOptions,
      start: "08:00:00",
      end: "15:30:00",
      timeAccess: true,
    };
  },
  created() {
    const sipData = localStorage.getItem("sipData");
    if (sipData) {
      this.sipData = JSON.parse(sipData);
      this.userLevel = this.sipData.level;
    }
  },
  methods: {
    checkTimeRange() {
      const now = moment().format("HH:mm:ss");
      if (now >= this.start && now <= this.end) {
        this.timeAccess = false;
        // console.log(
        //   "Waktu saat ini berada dalam rentang yang diinginkan (08:00:00 to 15:30:00)."
        // );
      } else {
        // console.log(now);
        // console.log("Waktu saat ini berada di luar rentang yang diinginkan.");
        this.timeAccess = true;
      }
    },
    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit("show-float-submenu", menu, offset);
    },
    handleHideFloatSubmenu: function () {
      this.$emit("hide-float-submenu");
    },
    handleCollapseOther: function (menu) {
      for (var i = 0; i < this.filteredSidebarMenu.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu);
      }
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified =
        !this.pageOptions.pageSidebarMinified;
    },
    handleSidebarFilter: function (e) {
      var value = e.target.value;
      value = value.toLowerCase();

      if (value) {
        for (var x = 0; x < this.filteredSidebarMenu.length; x++) {
          var title = this.filteredSidebarMenu[x].title.toLowerCase();
          var children = this.filteredSidebarMenu[x].children;

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show();

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand();
            }
          } else {
            if (children) {
              var hasActive = false;
              for (var y = 0; y < children.length; y++) {
                var title2 = children[y].title.toLowerCase();

                if (title2.search(value) > -1) {
                  hasActive = true;
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show();
                  this.$refs.sidebarNavList[x].searchExpand();
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand();
                  } else {
                    this.$refs.sidebarNavList[x].hide();
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide();
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide();
            }
          }
        }
      } else {
        for (var a = 0; a < this.filteredSidebarMenu.length; a++) {
          this.$refs.sidebarNavList[a].show();

          var submenu = this.filteredSidebarMenu[a].children;
          if (submenu) {
            for (var b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show();
            }
          }
        }
      }
      console.log("------");
    },
  },
  computed: {
    filteredSidebarMenu() {
      this.checkTimeRange();
      const filterMenu = (menu) => {
        if (menu.path === "/dashboard") {
          if (
            this.userLevel !== "admin" &&
            this.userLevel !== "loket" &&
            this.userLevel !== "kelurahan" &&
            this.userLevel !== "satgas" &&
            this.userLevel !== "penagih" &&
            this.userLevel !== "guest"
          ) {
            return false;
          }
        } else if (menu.path === "/data-pelanggan") {
          if (
            this.userLevel !== "admin" &&
            this.userLevel !== "loket" &&
            this.userLevel !== "kelurahan" &&
            this.userLevel !== "penagih" &&
            this.userLevel !== "satgas"
          ) {
            return false;
          }
        } else if (menu.path === "/data-transaksi") {
          if (
            this.userLevel !== "admin" &&
            this.userLevel !== "loket" &&
            (this.userLevel !== "kelurahan" || this.timeAccess)
          ) {
            return false;
          }
        } else if (menu.path === "/profil") {
          if (this.userLevel !== "user") {
            return false;
          }
        } else if (menu.path === "/master") {
          if (this.userLevel !== "admin") {
            return false;
          }
        } else if (menu.path === "/pengguna") {
          if (this.userLevel !== "admin") {
            return false;
          }
        } else if (menu.path === "/pengaduan") {
          if (this.userLevel !== "admin") {
            return false;
          }
        }

        if (menu.children && menu.children.length > 0) {
          menu.children = menu.children.filter(filterMenu);
        }

        return true;
      };
      return this.sidebarMenu.filter(filterMenu);
    },
  },
};
</script>
